@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

p {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: -10%;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
}

h2 {
    font-family: 'Nunito', sans-serif;
    padding-top: 5%;
}

.myOwnerChapter {
    padding-right: 2%;
}

.test {
    background-color: coral;
}

p {
    padding: 2% 10%;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
}

h2 {
    padding-top: 5%;
    font-family: 'Nunito', sans-serif;
    text-align: center;
}

.myTitle {
    margin-top: 5%;
}

.myFormArea {
    padding: 4% 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #282c34;
    border-radius: 20px;
}
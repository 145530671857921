@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

p {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
}

.myContainer {
    background-color: #282c34;
    padding: 2% 15%;
}

.myText {
    color: #FFFFFF;
    align-content: center;
}

.phone {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: #FFFFFF
}

.myMenuItem {
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 18px;
}

.myMenuItemArea {
    padding-left: 10px;
    padding-right: 10px;
    width: 8em;
}
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

p {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}

.myIcon {
    padding-bottom: 10%;
}

.myTextArea {
    padding: 5% 15%;
}

.myPartners {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.myfeedbackArea {
    padding: 5% 15%;
}

.myfeedbackArea h2 {
    font-style: italic;
    font-family: 'Nunito', sans-serif;
}

.myfeedbackIcon {
    padding-top: 15%;
}

.myfeedbackStar {
    padding-bottom: 15%;
}

.myfeedbackComment {
    padding-top: 15%;
    font-family: 'Nunito', sans-serif;
    font-style: italic;
    font-size: 16px;
}

.myContactButton {
    color: #FFFFFF;
    border-width: 2px;
    background-color: #DB222A;
    opacity: 0.8;
    padding: 15px;
    border-radius: 10px;
}
